body {
    background-color: black;
    color: white;
    overscroll-behavior: contain;
}

#home {
    height: 100%;
    max-width: 100vw;
}
.cap {
    text-transform: capitalize;
}
.sub-choice {
    font-size: 3vw;
    margin: 0;
}
.scale {
    direction: rtl;
}
#navbar {
    height: 10vh;
    .center-container {
        position: relative;
        .center {
            margin: 0;
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            left: 0;
            right: 0;
        }
    }
}
h4 {
    font-size: calc(1.275rem + 0.3vw);
    line-height: 2rem;
}
.title {
    font-size: 5vw;
}
.title-1 {
    font-size: 5.5vw;
}
.title-3 {
    font-size: 7vw;
}
.select-inner {
    min-height: fit-content;
}
.multiple-select {
    min-height: 40px;
    overflow: hidden;
    margin: auto;
    padding: 0px 15px 0px 15px;
    position: relative;
    display: inline-block;
    width: 100%;
    background: #4285f4;
    border: 0;
    border-radius: 24px;
    color: white;
    font-size: 4vw;
    transition: scale 0.33s ease-in-out;
}
.emoji-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.2s ease-in-out;
    .emoji {
        transition: all 0.2s ease-in-out;
    }
    h3 {
        margin-bottom: 0;
    }
    .selected {
        scale: 1.5;
    }
}

.sub-btn {
    animation: 2s;
}

.success {
    width: 3rem;
    height: 3rem;
    border-radius: 2rem;
    background-color: green;
    color: transparent;
}

.fade-in {
    animation: fadeIn ease 2s;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.bouncing {
    animation: bounce 0.5s;
}

@keyframes bounce {
    0% {
        scale: 0.5;
    }
    50% {
        scale: 1.2;
    }
    100% {
        scale: 1;
    }
}

.waiting {
    height: 100%;
    background-color: #222;
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes color {
    0% {
        background-color: #222;
    }
    50% {
        background-color: #4285f4;
    }
    100% {
        background-color: #222;
    }
}

.center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    //transition: all 1s ease-in-out;
}

.waiting-text {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.mchoice {
    width: auto;
    min-width: 40vw;
    height: auto;
    margin-top: 2rem;
    font-size: 20pt;
}

$slider-height: 40vh;
$slider-transform: calc($slider-height / 2);
.slider-wrapper {
    display: inline-block;
    width: 24px;
    height: $slider-height;
    padding: 2px;
}

.slider-wrapper input {
    position: absolute;
    //top: $slider-transform;
    //left: $slider-transform;
    width: $slider-height;
    height: 20px;
    margin: 0;
    //transform-origin: $slider-transform $slider-transform;
    transform: translate(-$slider-transform, $slider-transform) rotate(-90deg);
}
input[type="text"],
input[type="number"] {
    border-radius: 2rem;
    text-align: center;
    border: none;
    appearance: none;
    height: 40px;
    font-size: large;
    user-select: auto;
    &:focus-visible {
        border: none;
        outline: none;
    }
}
input[type="checkbox"] {
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}
input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: $slider-height;
    &::-webkit-slider-runnable-track {
        background-color: grey;
        border-radius: 20px;
        height: 20px;
    }
    &::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        margin-top: -5px; /* Centers thumb on the track */
        /*custom styles*/
        background-color: #0d6efd;
        height: 30px;
        width: 30px;
        border-radius: 30px;
    }
}
.multiple-image {
    animation: 2s;
}
.tip {
    text-align: center;
    height: 5vh;
}
.selected-image-container {
    position: absolute;
    width: 100%;
    top: 50%;
    opacity: 1;
    transform: translateY(-50%);
    transition: opacity 1s ease-in-out;
}
.selected-image {
    max-width: 100%;
    height: auto;
}
.non-selected-image {
    animation: hideMe 1s;
    animation-fill-mode: forwards;
}
.privacy-link {
    color: cyan;
}
@keyframes hideMe {
    0% {
        opacity: 1;
    }

    50% {
    }
    100% {
        opacity: 0;
    }
}

.number-display {
    width: 33vw;
    font-size: 20pt;
    border-radius: 2rem;
    &:focus-visible {
        outline: none;
    }
}
.point-in-picture {
    touch-action: none;
}
svg.sticky {
    pointer-events: none;
    width: 50px;
    height: 50px;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
}
svg circle.blue {
    animation: stickyAnimBlue 2s ease-in-out infinite;
    animation-direction: alternate;
}
@keyframes stickyAnimBlue {
    0% {
        stroke: white;
    }

    50% {
        stroke: #4285f4;
    }
    100% {
        stroke: white;
    }
}
svg circle.red {
    animation: stickyAnimRed 2s ease-in-out infinite;
    animation-direction: alternate;
}
@keyframes stickyAnimRed {
    0% {
        stroke: white;
    }

    50% {
        stroke: red;
    }
    100% {
        stroke: white;
    }
}
.choice-selected {
    background-color: grey !important;
    scale: 0.9;
    transition: all 0.33s ease-in-out;
}
.selected-scale {
    scale: 1.1;
    transition: all 0.33s ease-in-out;
}
.form_validation {
    opacity: 0;
    transition: all 0.33s ease-in-out;
}

@import "complete.scss";
@import "buttonAnimation.scss";
@import "mediaQueries.scss";
