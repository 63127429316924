// Config
$circle-size: 40px;

// Unfortunately we need a wrapper element containing the fixed width for centering the button within the animtion (you could also apply the width as an inline style).
.loading-btn-wrapper {
    display: inline-block;
    width: 100%;
    height: $circle-size;
    min-height: $circle-size;

    text-align: center;
}

.loading-btn {
    $root: &;

    position: relative;

    display: inline-block;
    width: 100%;
    height: 100%;

    background: #8bc34a;

    border: 0;
    border-radius: 24px;

    cursor: pointer;

    transition: all 0.33s ease-in-out;

    // &:hover {
    //     background: #4285f4;
    // }

    &,
    &:focus {
        outline: none;
    }

    // Styles for all states
    &--pending {
        // Morph button to circle (width equals height)
        width: 33vw;

        // Prevent any further clicks triggering events during animation
        pointer-events: none;
        cursor: default;

        // Hide text
        #{$root}__text {
            opacity: 0;
        }
    }
    &--success {
        // Morph button to circle (width equals height)
        width: 33vw;

        // Prevent any further clicks triggering events during animation
        pointer-events: none;
        cursor: default;

        // Hide text
        #{$root}__text {
            opacity: 0;
        }
    }
    &--fail {
        // Morph button to circle (width equals height)
        width: 33vw;

        // Prevent any further clicks triggering events during animation
        pointer-events: none;
        cursor: default;

        // Hide text
        #{$root}__text {
            opacity: 0;
        }
    }

    // State "pending"
    // Show loading indicator
    &--pending:before {
        content: "";

        position: absolute;
        top: 50%;
        left: 50%;

        display: inline-block;

        // Can't use percentage here as we already show this icon during morph animation
        height: #{$circle-size * 0.7};
        width: #{$circle-size * 0.7};

        border: 3px solid rgba(255, 255, 255, 0.33);
        border-top-color: #fff;
        border-radius: 50%;

        animation: loading-btn--fade-in 0.33s ease,
            loading-btn--rotation 0.66s linear 0s infinite;
    }

    // Success state - show check icon
    &--success {
        // Different background color (also on hover)
        &,
        &:hover {
            background: #8bc34a !important;
        }

        // Use "after" pseudo to trigger new fade in animation, as "before" is already used on "--pending"
        &:after {
            content: "Submitted";
            color: white;
            //position: absolute;
            top: 50%;
            left: 50%;

            // Simulate checkmark icon
            //display: inline-block;
            //height: 25%;
            //width: 50%;

            //border: 3px solid #fff;
            //border-top-width: 0;
            //border-right-width: 0;

            transform: translate(-50%, -75%); //rotate(-45deg);

            animation: loading-btn--fade-in 0.6s ease;
        }
    }

    // Fail state - show cross icon
    &--fail {
        // Different background color (also on hover)
        &,
        &:hover {
            background: #ff5722 !important;
        }

        // Use "after" pseudo to trigger new fade in animation, as "before" is already used on "--pending"
        &:after {
            content: "Oops!";
            color: white;
            position: absolute;
            top: 50%;
            left: 50%;

            // Simulate cross icon
            display: inline-block;
            height: 65%;
            width: 65%;

            // Using background gradient is the only solution creating a cross with a single element
            // background: linear-gradient(
            //         to bottom,
            //         transparent 44%,
            //         #fff 44%,
            //         #fff 56%,
            //         transparent 56%
            //     ),
            //     linear-gradient(
            //         to right,
            //         transparent 44%,
            //         #fff 44%,
            //         #fff 56%,
            //         transparent 56%
            //     );

            transform: translate(-50%, -50%);

            animation: loading-btn--fade-in 0.6s ease;
        }
    }

    // Text has to be positioned absolute in order prevent line-breaks or trimming of text when morphing button to circle.
    &__text {
        position: absolute;
        min-width: 85%;
        //height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        overflow: hidden;
        white-space: break-spaces;
        text-overflow: ellipsis;

        font-size: 5vw;
        color: #fff;

        transition: inherit;
    }
}

/**
 * Animations
 */

@keyframes loading-btn--fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes loading-btn--rotation {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

/**
 * Optical stuff - has nothing todo with button animation.
 */

.state-list {
    margin-bottom: 12px;

    .loading-btn-wrapper {
        background: repeating-linear-gradient(
            45deg,
            #fff,
            #fff 10px,
            #f0f0f0 10px,
            #f0f0f0 20px
        );
    }
}

.loading-btn-wrapper {
    & + & {
        margin-left: 8px;
    }
}
